.m-nav .m-nav__item > .m-nav__link.active .m-nav__link-text,
.m-nav .m-nav__item > .m-nav__link.active .m-nav__link-icon {
  color: #22b9ff;
}

@media (max-width: 1200px) {
  .m-timeline-1
    .m-timeline-1__items
    .m-timeline-1__item.m-timeline-1__item--left
    .m-timeline-1__item-time {
    right: 3.57rem;
    left: 3.57rem;
  }
}

@media (max-width: 767px) {
  .media {
    flex-direction: column;
  }
}
