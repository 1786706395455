.m-timeline-3 .m-timeline-3__item .m-timeline-3__item-time {
  width: 7rem;
  font-size: 14px;
}

.m-timeline-3 .m-timeline-3__item .m-timeline-3__item-desc {
  padding-left: 9rem;
  display: block;
}

.m-timeline-3 .m-timeline-3__item:before {
  height: 98%;
  left: 7.5rem;
}
